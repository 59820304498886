<template>
  <div>
    <h3 style="font-weight:500;text-align:center;margin-top:0;">{{ ruleForm.type === 'tax' ? '关税对账登记' : '物流对账登记' }}</h3>
    <el-form ref="ruleForm" :model="ruleForm" label-width="130px" :rules="rules">
      <el-row v-if="$route.query.type==='logistics'" :span="24">
        <el-col :span="6">
          <el-form-item label="发运单号：">
            <el-input v-model="ruleForm.shipNo" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="结算主体：">
            <el-select
              v-model="ruleForm.settleEntityCode"
              style="width:100%;"
              disabled
              value-key="id"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in cargoOwnerNameList"
                :key="item.cargoOwnerName"
                :label="item.cargoOwnerName"
                :value="item.cargoOwnerCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="来源单号：" prop="sourceNo">
            <el-input v-model="ruleForm.sourceNo" :disabled="$route.query.flag==='view'" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="承运商：" prop="carrierCode">
            <el-select
              v-model="ruleForm.carrierCode"
              style="width:100%;"
              value-key="id"
              placeholder="请选择"
              filterable
              clearable
              disabled
            >
              <el-option
                v-for="item in newCarrierList"
                :key="item.id"
                :label="item.vendorName"
                :value="item.vendorCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="账期截止日期：" prop="dueDate">
            <el-date-picker
              v-model="ruleForm.dueDate"
              type="date"
              style="width:100%;"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              :disabled="$route.query.flag==='view'"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="目的港：">
            <el-input v-model="ruleForm.destHarbor" :disabled="$route.query.flag==='view'" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开航日期：">
            <el-date-picker
              v-model="ruleForm.sailingDate"
              :disabled="$route.query.flag==='view'"
              type="date"
              style="width:100%;"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="$route.query.type==='tax'">
        <el-col :span="6">
          <el-form-item label="发运单号：">
            <el-input v-model="ruleForm.shipNo" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="发运仓：">
            <el-input v-model="ruleForm.shipWarehouse" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="目的仓：">
            <el-input v-model="ruleForm.destWarehouse" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="进口方：" prop="settleEntityCode">
            <el-select
              v-model="ruleForm.settleEntityCode"
              style="width:100%;"
              disabled
              value-key="id"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in cargoOwnerNameList"
                :key="item.cargoOwnerName"
                :label="item.cargoOwnerName"
                :value="item.cargoOwnerCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="清关行：" prop="carrierCode">
            <el-select
              v-model="ruleForm.carrierCode"
              style="width:100%;"
              value-key="id"
              disabled
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="item in newCarrierList"
                :key="item.id"
                :label="item.vendorName"
                :value="item.vendorCode"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="币种：" prop="currencyCode">
            <el-select
              v-model="ruleForm.currencyCode"
              style="width:100%;"
              disabled
              value-key="id"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="e in _getAllCommodityDict('CURRENCY_TYPE')"
                :key="e.val"
                :label="e.label"
                :value="e.val"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="发票号：" prop="sourceNo">
            <el-input v-model="ruleForm.sourceNo" :disabled="$route.query.flag==='view'" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="提货港：">
            <el-input v-model="ruleForm.pickHarbor" :disabled="$route.query.flag==='view'" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="入境日期：">
            <el-date-picker
              v-model="ruleForm.entryDate"
              :disabled="$route.query.flag==='view'"
              type="date"
              style="width:100%;"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="发票日期：">
            <el-date-picker
              v-model="ruleForm.invoiceDate"
              :disabled="$route.query.flag==='view'"
              type="date"
              style="width:100%;"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="到期日期：" prop="expireDate">
            <el-date-picker
              v-model="ruleForm.expireDate"
              :disabled="$route.query.flag==='view'"
              type="date"
              style="width:100%;"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="提单号：" :prop="$route.query.type==='logistics'?'ladingNumber':''">
            <el-input v-model="ruleForm.ladingNumber" :disabled="$route.query.flag==='view'" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="货柜号：" :prop="$route.query.type==='logistics'?'containerNo':''">
            <el-input v-model="ruleForm.containerNo" :disabled="$route.query.flag==='view'" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 小数保留2位精度 -->
          <el-form-item label="重量（kg）：">
            <el-input
              v-model="ruleForm.weight"
              type="number"
              :disabled="$route.query.flag==='view'"
              @blur="ruleForm.weight=blurWeight($event)"
              @wheel.native.prevent="stopScroll($event)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 大于0的整数 /^[1-9]d*$/-->
          <el-form-item label="数量（双）：">
            <el-input
              v-model="ruleForm.qty"
              type="number"
              :disabled="$route.query.flag==='view'"
              oninput="value=value.replace(/[^0-9]/g,'')"
              @blur="ruleForm.qty=blurqty($event)"
              @wheel.native.prevent="stopScroll($event)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <!-- 小数保留2位精度 -->
          <el-form-item label="体积（m³）：">
            <el-input
              v-model="ruleForm.volume"
              type="number"
              :disabled="$route.query.flag==='view'"
              @blur="ruleForm.volume=blurWeight($event)"
              @wheel.native.prevent="stopScroll($event)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="渠道：">
            <el-select
              v-model="ruleForm.logisticsChannel"
              style="width:100%;"
              value-key="id"
              placeholder="请选择"
              filterable
              clearable
              disabled
            >
              <el-option
                v-for="item in _getAllCommodityDict('LOGISTICS_CHANNEL_TYPE')"
                :key="item.val"
                :label="item.label"
                :value="item.val"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="出港日期：">
            <el-input v-model="ruleForm.sailingDate" disabled />
          </el-form-item>
        </el-col>
        <el-col v-if="$route.query.flag==='edit'" :span="8">
          <el-form-item label="附件：">
            <!-- <input ref="fileId" type="file" style="display:inline-block;width:180px;" limit="1" @change="getFile"><i v-if="file" class="el-icon-circle-close" @click="clearFile" /> -->
            <input ref="fileId" type="file" style="display: none" limit="1" @change="getFile">
            <el-button type="text" @click="handleUploadBtnClick">点击上传</el-button><span
              v-if="file.name|| ruleForm.filePath"
              class="ml-2"
            >{{ file.name|| ruleForm.filePath.split('/')[ruleForm.filePath.split('/').length-1] }}<i
              v-if="file||ruleForm.filePath"
              class="el-icon-circle-close ml-2"
              @click="clearFile"
            /></span>
          </el-form-item>
        </el-col>
        <el-col v-else :span="8">
          <el-form-item label="附件：">
            <el-button v-if="ruleForm.filePath" type="text" @click="downloadByThoms(ruleForm.filePath)">
              {{ ruleForm.filePath.split('/')[ruleForm.filePath.split('/').length-1] }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div v-if="$route.query.type==='logistics'">
      <div v-for="(item,i) in ruleForm.auditItemVOList" :key="i">
        <div class="logistic mb-3">
          <div style="width:100%;">
            <el-row class="mt-1" type="flex" justify="start" style="padding:12px;">
              <el-col class="mr-2"> {{ item.feeSnippet }}</el-col>
            </el-row>
            <el-form ref="Form" :model="item" :rules="itemRules" @submit.native.prevent>
              <div style="padding:12px;border-top: 1px solid #333;" class="mt-1">
                <Table
                  :max-height="'300px'"
                  class="mt-1"
                  :columns="logisticsReconcileColumns"
                  :table-data="item.auditItemDetailsList"
                  :width="900"
                >
                  <el-table-column slot="special" label="对账费用" align="center" width="150">
                    <template slot-scope="scope">
                      <!-- :prop="'auditItemDetailsList.' + scope.$index + '.auditConfirmFees'"  :rules="itemRules.auditConfirmFees" -->
                      <el-form-item>
                        <el-input
                          v-model="scope.row.auditConfirmFees"
                          type="number"
                          :disabled="$route.query.flag==='view'"
                          @blur="scope.row.auditConfirmFees=blurFee($event,scope.row)"
                          @wheel.native.prevent="stopScroll($event)"
                        />
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column slot="tax" label="税率" align="center" width="220">
                    <template slot-scope="scope">
                      <el-input-number
                        v-model="scope.row.taxRate"
                        controls-position="right"
                        :disabled="$route.query.flag==='view'"
                        :precision="0"
                        :step="1"
                        :min="0"
                        @change="handleChange($event,i)"
                      />
                    </template>
                  </el-table-column>
                </Table>
              </div>
            </el-form>
            <div
              v-if="item.auditItemSpecialDetailsVOS.length"
              style="padding:12px;border-top: 1px solid #333;"
              class="mt-1"
            >
              <div style="width:100px;font-size:14px;">特殊费用：</div>
              <Table
                :max-height="'300px'"
                class="mt-1"
                :columns="specialFeeColumns"
                :table-data="item.auditItemSpecialDetailsVOS"
                :width="900"
              >
                <el-table-column slot="view" label="操作">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.filePath" type="text" @click="downloadByThoms(scope.row.filePath)">下载
                    </el-button>
                  </template>
                </el-table-column>
              </Table>
            </div>

          </div>
        </div>
      </div>
      <el-row>
        <el-row type="flex" justify="end">
          <el-col :span="6">账单费用合计：【CNY】{{ cny }}</el-col>
          <el-col :span="6">填报费用合计：【CNY】{{ auditConfirmFeesCny }}</el-col>
          <el-col :span="6">差异合计：【CNY】{{ differenceFeesCny }}</el-col>
        </el-row>
        <el-row type="flex" justify="end">
          <el-col :span="6" style="padding-left:112px;">【USD】{{ usd }}</el-col>
          <el-col :span="6" style="padding-left:112px;">【USD】{{ auditConfirmFeesUsd }}</el-col>
          <el-col :span="6" style="padding-left:82px;">【USD】{{ differenceFeesUsd }}</el-col>
        </el-row>
      </el-row>
    </div>
    <!-- 关税费用登记 -->
    <div v-if="$route.query.type==='tax'" class="three">
      <div v-for="(item,i) in ruleForm.auditItemVOList" :key="i">
        <el-form ref="Form" :model="item" :rules="itemRules" @submit.native.prevent>
          <Table
            :max-height="'300px'"
            class="mt-1"
            :columns="taxReconcileColumns"
            :table-data="item.auditItemDetailsList"
            :width="900"
          >
            <el-table-column slot="special" label="对账税费" align="center" width="150">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'auditItemDetailsList.' + scope.$index + '.auditConfirmFees'"
                  :rules="itemRules.auditConfirmFees"
                >
                  <el-input
                    v-model="scope.row.auditConfirmFees"
                    type="number"
                    :disabled="$route.query.flag==='view'"
                    @blur="scope.row.auditConfirmFees=blurTax($event,scope.row)"
                    @wheel.native.prevent="stopScroll($event)"
                  />
                </el-form-item>
              </template>
            </el-table-column>
          </Table>
        </el-form>
      </div>
    </div>
    <div v-if="$route.query.type==='tax'" class="result mt-3 mr-2">
      <div>关税：{{ taxFee }}</div>
      <div>关税差异：{{ taxFeeDiff }}</div>
    </div>
    <el-row style="text-align:center;" class="mt-3">
      <el-button type="primary" @click="cancel">{{ cancelTxt }}</el-button>
      <el-button v-if="$route.query.flag!=='view'" type="primary" :loading="saveLoading" @click="save(2)">保存并对账</el-button>
      <el-button v-if="$route.query.flag!=='view'" type="primary" :loading="saveLoading" @click="save(1)">保存</el-button>
    </el-row>
  </div>
</template>

<script>
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Table from '@/components/Table'
import { prepareColumns, specialFeeColumns, detailColumns, logisticsReconcileColumns, taxDetailColumns, taxReconcileColumns } from '@/utils/table-columns.js'
import { getcargoownerList, getVendorListByVendorType } from '@/api/select.js'
import { deepClone } from '@/utils'
import { reconciliationRegistrationSave, reconciliationRegistrationSubmit, getReconciliationInfo, getAuditInfoByItemId, downloadByThoms } from '@/api/carriers'
import { validAmount, validAmountDialog } from '@/utils/validate'
import { FloatAdd, FloatSub } from '@/utils/acc'
import defaultSettings from '@/settings'

export default {
  components: { Table },
  mixins: [commodityInfoDict],
  data() {
    return {
      reconciliationRegistrationSave,
      reconciliationRegistrationSubmit,
      saveLoading: false,
      newCarrierList: [],
      auditType: '',
      taxFeeDiff: '',
      taxFee: '',
      index: 0,
      usd: 0,
      cny: 0,
      auditConfirmFeesUsd: 0,
      differenceFeesUsd: 0,
      auditConfirmFeesCny: 0,
      differenceFeesCny: 0,
      taxDetailColumns,
      logisticsReconcileColumns,
      detailColumns,
      taxReconcileColumns,
      fileList: [],
      specialFeeColumns,
      cargoOwnerNameList: [],
      prepareColumns,
      file: '',
      Loading: false,
      auditStatus: '',
      rules: {
        sourceNo: [{ required: true, message: '必填', trigger: 'blur' }],
        ladingNumber: [{ required: true, message: '必填', trigger: 'blur' }],
        containerNo: [{ required: true, message: '必填', trigger: 'blur' }],
        carrierCode: [{ required: true, message: '必填', trigger: 'change' }],
        dueDate: [{ required: true, message: '必填', trigger: 'change' }],
        settleEntityCode: [{ required: true, message: '必填', trigger: 'change' }],
        currencyCode: [{ required: true, message: '必填', trigger: 'change' }],
        expireDate: [{ required: true, message: '必填', trigger: 'blur' }]
      },
      itemRules: {
        auditConfirmFees: [{ required: true, message: '必填', trigger: 'blur' }]

      },
      ruleForm: {
        flag: '',
        auditItemVOList: [],
        shipNo: '',
        settleEntityCode: '',
        sourceNo: '',
        carrierCode: '',
        dueDate: '',
        destHarbor: '',
        sailingDate: '',
        pickHarbor: '',
        entryDate: '',
        containerNo: '',
        ladingNumber: ''

      }
    }
  },
  computed: {
    api() {
      return this.auditStatus === 2 ? reconciliationRegistrationSubmit : reconciliationRegistrationSave
    },
    cancelTxt() {
      const flag = this.$route.query.flag
      return flag === 'view' ? '返回' : '取消'
    },
    saveParams() {
      const obj = deepClone(this.ruleForm)
      let auditConfirmAmount = 0
      obj.auditItemVOList = obj.auditItemVOList.map(item => {
        auditConfirmAmount = 0
        delete item.auditItemSpecialDetailsVOS
        item.auditItemDetailsList = item.auditItemDetailsList.map(e => {
          auditConfirmAmount = FloatAdd(Number(auditConfirmAmount), Number(e.auditConfirmFees))
          return { ...e, auditConfirmFees: e.auditConfirmFees || null }
        })
        return { ...item, auditStatus: this.auditStatus, sourceNo: obj.sourceNo, auditConfirmAmount }
      })
      obj.auditStatus = this.auditStatus
      obj.auditType = obj.auditItemVOList[0].auditType
      const formDataParams = new FormData()
      const params = Object.assign({}, { file: this.file, jsonContent: JSON.stringify(obj) })
      Object.keys(params).forEach(key => formDataParams.set([key], params[key]))
      return formDataParams
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (val) {
        this._getcargoownerList()
      }
    }
  },
  async mounted() {
    this._getcargoownerList()
    this._getVendorListByVendorType()
    this.ruleForm.type = this.$route.query.type
    this.ruleForm.flag = this.$route.query.flag
    this.auditType = this.$route.query.auditType
    // 新增对账 获取初始信息
    if (this.$route.query.list) {
      const { datas } = await getReconciliationInfo(JSON.parse(this.$route.query.list))
      datas.map((item, index) => {
        item.auditItemVOList = item.auditItemVOList.map(e => {
          const auditItemSpecialDetailsVOS = []
          e.auditItemDetailsList.map(ee => {
            ee.currency = e.currency
            ee.currencyCode = e.currencyCode
            item.currency = e.currency
            item.currencyCode = e.currencyCode
            ee.taxRate = ee.taxRate || 0
            ee.auditItemSpecialDetailsVOS.map(eee => {
              auditItemSpecialDetailsVOS.push(eee)
            })
          })
          return { ...e, auditItemSpecialDetailsVOS: auditItemSpecialDetailsVOS, auditType: this.auditType }
        })
        this.$set(item, 'auditItemVOList', item.auditItemVOList)
        this.$set(item, 'index', index)
        this.$set(item, 'dueDate', item.dueDate || '')
        this.$set(item, 'auditType', this.auditType)
      })
      this.datas = datas
    } else {
      // 修改、查看
      const arr = []
      const { code, datas } = await getAuditInfoByItemId({ itemId: this.$route.query.itemId })
      if (code === 0) {
        arr.push(datas)
        arr.map((item, index) => {
          item.auditItemVOList = item.auditItemVOList.map(e => {
            const auditItemSpecialDetailsVOS = []
            e.auditItemDetailsList.map(ee => {
              ee.currency = e.currency
              ee.currencyCode = e.currencyCode
              item.currency = e.currency
              item.currencyCode = e.currencyCode
              ee.taxRate = ee.taxRate || 0
              ee.auditItemSpecialDetailsVOS.map(eee => {
                auditItemSpecialDetailsVOS.push(eee)
              })
            })
            return { ...e, auditItemSpecialDetailsVOS: auditItemSpecialDetailsVOS, auditType: this.auditType }
          })
          this.$set(item, 'auditItemVOList', item.auditItemVOList)
          this.$set(item, 'index', index)
          this.$set(item, 'dueDate', item.dueDate || '')
          this.$set(item, 'auditType', this.auditType)
        })
        this.datas = arr
      }
    }
    const obj = this.datas.find(item => item.index === 0) || []
    if (obj.shipNo) {
      this.ruleForm.auditItemVOList = obj.auditItemVOList
      // 选择发运单进对账页面，初始信息
      const flag = this.ruleForm.flag === 'edit' && this.ruleForm.type === 'logistics' && this.$route.query.list
      this.ruleForm = Object.assign({}, this.ruleForm, obj, { sourceNo: flag ? '' : obj.sourceNo }, { logisticsChannel: String(obj.logisticsChannel) })
      if (this.ruleForm.filePath) {
        this.file = this.ruleForm.filePath
      }
      this.getResultPrice()
    }
    this.$route.meta.title = this.ruleForm.type === 'tax' ? '关税费用对账' : this.ruleForm.type === 'logistics' ? '物流费用对账' : ''
    this.setTitle()
  },
  methods: {
    stopScroll(evt) {
      evt = evt || window.event
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault()
        evt.stopPropagation()
      } else {
        // IE
        evt.cancelBubble = true
        evt.returnValue = false
      }
      return false
    },
    async _getVendorListByVendorType() {
      const { datas } = await getVendorListByVendorType({ vendorType: '6' })
      this.newCarrierList = datas
    },
    handleUploadBtnClick() {
      this.$refs.fileId.dispatchEvent(new MouseEvent('click'))
    },
    setTitle() {
      document.title = `${this.$route.meta.title} - ${defaultSettings.title}`
    },
    blurqty(val) {
      const value = val.target.value
      var reg = /^\+?[1-9]\d*$/
      if (!reg.test(value)) {
        return ''
      } else {
        return value
      }
    },
    blurWeight(val) {
      const value = val.target.value
      // 小数
      const flag = value && value.indexOf('.') !== -1 && value.split('.').length === 2 && value.split('.')[1].length > 2
      if (validAmountDialog(value) && flag) {
        return Number(value).toFixed(2)
      }
      if (validAmountDialog(value)) {
        return value
      }

      if (!validAmountDialog(value)) {
        return ''
      }
    },

    handleChange(value, i) {
      if (!value) {
        this.ruleForm.auditItemVOList.find((item, index) => index === i).auditItemDetailsList.map(
          item => {
            this.$nextTick(() => {
              this.$set(item, 'taxRate', 0)
            })
          }
        )
      } else {
        this.ruleForm.auditItemVOList.find((item, index) => index === i).auditItemDetailsList.map(
          item => {
            this.$set(item, 'taxRate', value)
          }
        )
      }
    },
    blurTaxRate(val, i) {
      const value = val.target.value
      console.log(value)
      if (!value) {
        this.ruleForm.auditItemVOList.find((item, index) => index === i).auditItemDetailsList.map(
          item => {
            this.$set(item, 'taxRate', 0)
          }
        )
        return 0
      } else {
        this.ruleForm.auditItemVOList.find((item, index) => index === i).auditItemDetailsList.map(
          item => {
            this.$set(item, 'taxRate', value)
          }
        )
        return value
      }
    },
    // 关税对账税费
    blurTax(val, item) {
      const value = val.target.value
      this.taxFeeDiff = 0
      this.taxFee = 0
      console.log(validAmountDialog(value))
      const flag = value && value.indexOf('.') !== -1 && value.split('.').length === 2 && value.split('.')[1].length > 2
      // 系统保留小数点后两位
      if (validAmountDialog(value) && flag) {
        this.$set(item, 'differenceFees', FloatSub(Number(value).toFixed(2), item.feeItemFees))
        this.taxFeeDiff = item.differenceFees
        this.taxFee = Number(value).toFixed(2)
        return Number(value).toFixed(2)
      }
      if (validAmountDialog(value)) {
        this.$set(item, 'differenceFees', FloatSub(value, item.feeItemFees))
        this.taxFeeDiff = item.differenceFees
        this.taxFee = value
        return value
      }

      if (!validAmount(value)) {
        this.$set(item, 'differenceFees', FloatSub(0, item.feeItemFees))
        this.taxFeeDiff = item.differenceFees
        this.taxFee = ''
        return ''
      }
    },
    blurFee(val, item) {
      this.cny = 0
      this.usd = 0
      this.auditConfirmFeesUsd = 0
      this.differenceFeesUsd = 0
      this.auditConfirmFeesCny = 0
      this.differenceFeesCny = 0
      const value = val.target.value
      const flag = value && value.indexOf('.') !== -1 && value.split('.').length === 2 && value.split('.')[1].length > 2

      // 系统保留小数点后两位
      if (validAmountDialog(value) && flag) {
        this.$set(item, 'differenceFees', FloatSub(Number(item.auditConfirmFees).toFixed(2), FloatAdd(Number(item.feeItemFees), Number(item.feeItemSpecialFees))))
        this.getResultPrice()
        return Number(value).toFixed(2)
      }
      if (validAmountDialog(value)) {
        this.$set(item, 'differenceFees', FloatSub(item.auditConfirmFees, FloatAdd(Number(item.feeItemFees), Number(item.feeItemSpecialFees))))
        this.getResultPrice()
        return value
      }
      if (!validAmountDialog(value)) {
        // this.$set(item, 'differenceFees', FloatSub(0, FloatAdd(Number(item.feeItemFees), Number(item.feeItemSpecialFees))))
        this.$set(item, 'differenceFees', '')
        this.getResultPrice()
        return ''
      }
    },
    getResultPrice() {
      this.ruleForm.auditItemVOList.map(item =>
        item.auditItemDetailsList.map(e => {
          if (e.currencyCode === 'USD') {
            this.usd = FloatAdd(this.usd, FloatAdd(e.feeItemFees, Number(e.feeItemSpecialFees)))
            this.auditConfirmFeesUsd = FloatAdd(Number(this.auditConfirmFeesUsd), Number(e.auditConfirmFees))
            this.differenceFeesUsd = FloatAdd(Number(this.differenceFeesUsd), Number(e.differenceFees))
            // 关税
          }
          if (e.currencyCode === 'CNY') {
            this.cny = FloatAdd(this.cny, FloatAdd(Number(e.feeItemFees), Number(e.feeItemSpecialFees)))
            this.auditConfirmFeesCny = FloatAdd(Number(this.auditConfirmFeesCny), Number(e.auditConfirmFees))
            this.differenceFeesCny = FloatAdd(Number(this.differenceFeesCny), Number(e.differenceFees))
          }
          this.taxFee = FloatAdd(Number(this.taxFee), Number(e.auditConfirmFees))
          this.taxFeeDiff = FloatAdd(Number(this.taxFeeDiff), Number(e.differenceFees))
        })
      )
    },
    save(auditStatus) {
      let flag = true
      this.$refs.Form.find(item => {
        item.validate((valid) => {
          if (!valid) {
            flag = false
            return false
          }
        })
      })
      // 每个费用片段->费用项目->对账费用全填或全不填
      const flag1 = this.ruleForm.auditItemVOList.every(item => {
        if (item.auditItemDetailsList.every(e => Boolean(item.auditItemDetailsList[0].auditConfirmFees) === Boolean(e.auditConfirmFees))) {
          return true
        }
      })
      //  且存在一个费用片段全填
      const flag2 = this.ruleForm.auditItemVOList.find(item => {
        if (item.auditItemDetailsList.every(e => e.auditConfirmFees)) {
          return true
        }
      })

      // 是否存在有差异
      const flag3 = this.ruleForm.auditItemVOList.find(item => {
        if (item.auditItemDetailsList.find(e => Number(e.differenceFees))) { return true }
      })

      this.$refs.ruleForm.validate(valid => {
        if (valid && flag) {
          this.auditStatus = auditStatus
          // 存在差异弹确认框
          if (auditStatus === 2) {
            if (!flag1) {
              return this.$message.warning('存在费用片段未填写完整')
            }
            if (!flag2) {
              return this.$message.warning('请至少填写一个费用片段')
            }
            flag3 ? this.$confirm('费用项目存在差异, 是否继续?', '提示', {
              confirmButtonText: '确定',
              showCancelButton: true,
              type: 'warning'
            }).then(() => {
              this.saveSure()
            }) : this.saveSure()
          } else {
            this.saveSure()
          }
        }
      })
    },
    async saveSure() {
      try {
        this.saveLoading = true
        const { code, msg } = await this.api(this.saveParams)
        if (code === 0) {
          this.index++
          // 清空文件
          this.file = ''
          this.$refs.fileId.value = ''
          this.cny = 0
          this.usd = 0
          this.auditConfirmFeesUsd = 0
          this.differenceFeesUsd = 0
          this.auditConfirmFeesCny = 0
          this.differenceFeesCny = 0
          this.$notify({
            message: msg,
            type: 'success'
          })
          if (this.datas.length) {
            const obj = this.datas.find(item => item.index === this.index) || []
            if (obj.shipNo) {
              this.ruleForm.auditItemVOList = obj.auditItemVOList
              const flag = this.ruleForm.flag === 'edit' && this.ruleForm.type === 'logistics' && this.$route.query.list
              this.ruleForm = Object.assign({}, this.ruleForm, obj, { sourceNo: flag ? '' : obj.sourceNo }, { logisticsChannel: String(obj.logisticsChannel) })
              if (this.ruleForm.filePath) {
                this.file = this.ruleForm.filePath
              }
              this.getResultPrice()
            } else {
              this.$router.go(-1)
            }
          }
        }
      } finally {
        this.saveLoading = false
      }
    },
    clearFile() {
      this.file = ''
      this.$refs.fileId.value = ''
      this.ruleForm.filePath = ''
    },
    download(url) {
      window.open(url, '_blank')
    },
    async downloadByThoms(httpUrlString) {
      const res = await downloadByThoms({ httpUrlString })
      const url = window.URL.createObjectURL(new Blob([res], { type: 'application/octet-stream;charset=utf-8' }))
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      const arr = httpUrlString.split('/')[httpUrlString.split('/').length - 1].split('.')
      const fileName = arr[0]
      link.setAttribute('download', fileName + '.' + arr[1])
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href)
    },
    getSummaries(param) {
      const { columns, data } = param || {}
      const sums = []
      const arr = ['totalPrice', 'paymentAmount', 'purchasePairs', 'purchaseAmount', 'surplusPairs', 'surplusAmount']
      columns && columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (arr.includes(column.property)) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            const num = Number(values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0))
            sums[index] = (num + '').indexOf('.') === -1 ? num : num.toFixed(2)
          } else {
            sums[index] = ''
          }
        }
      })
      return sums
    },

    cancel() {
      this.$router.go(-1)
    },
    downTemplate() { },
    async _getcargoownerList() {
      const { datas } = await getcargoownerList()
      this.cargoOwnerNameList = datas
    },
    getFile() {
      const file = this.$refs.fileId.files[0]
      this.file = file
    },
    submit() { },
    fileChange(file, fileList) {
      console.log(file, fileList)
      this.file = file.raw
    },
    uploadFile() { }
  }
}
</script>

<style scoped lang="scss">
.logistic {
  width: 100%;
  // height:400px;
  border: 1px solid #333;
}
.first {
  /deep/.el-input__inner {
    width: 110px !important;
  }
}
/deep/ .el-form-item__label {
  padding-right: 3px;
  font-weight: normal !important;
  line-height: 36px;
}
.two {
  width: 100%;
  border: 1px solid #333;
}
.three {
  padding: 20px;
  width: 100%;
  border: 1px solid #333;
}
.result {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/deep/.el-table .cell {
  .el-form-item {
    margin-bottom: 0px;
  }
}
 /deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
